import { api, returningError } from 'src/api';
import { store } from 'src/redux';
import { getPrefixEndpoints } from 'src/utils';

export const getInfoByPlaceId = async (placeId: string) => {
   try {
      const { data }: any = await api.get(`/${getPrefixEndpoints(store.getState().app.locale)}/v1/gmaps/place`, {
         params: {
            place_id: placeId,
         },
      });
      return data;
   } catch (error: any) {
      return returningError(error);
   }
};
