import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
/**
    function to caliptalize strings
    * @param value: string to capitalize
    Example:
    receive: SOME WORD, function returns => Some Word
    receive: some word, function returns => Some Word
*/
export const ucFirst = (value: string) => {
   // empezar todas las palabras con mayuscula incluso si hay un espacio en un nombre o apellido compuesto
   value.replace(/\b\w/g, (val) => val.toUpperCase());
   // creamos arreglo temporal
   const temp = value.split(' ');
   // Se asegura que solo la primer letra de cada palabra comience con mayúscula
   const result = temp.map((key) => {
      return key.charAt(0).toUpperCase() + key.toLowerCase().slice(1);
   });
   return result.join(' ');
};

/**
 * @param value: number to convert to Hour with minutes
 */
export const convertToHourMinutes = (value: number) => {
   const hours: number = value / 60;
   // validate Hours
   if (hours >= 1) {
      // validate if is exactly an Hour
      const getHours = parseInt(String(hours));
      const getMinutes = value - getHours * 60;
      return `${getHours} h ${getMinutes > 0 ? `${getMinutes} min` : ''}`;
   } else {
      // return only minutes
      return `${value} min`;
   }
};

/**
 * @param address: array of google address component
 * @param prop: string to search
 * @param shortName: boolean var
 */
export const getProperties = (
   address: Array<{
      long_name: string;
      short_name: string;
      types: string[];
   }>,
   prop: string,
   shortName = false
) => {
   if (address) return address.find((item: any) => item.types.includes(prop))?.[shortName ? 'short_name' : 'long_name'] || '';
   return '';
};

export const disabledCurrentDate: RangePickerProps['disabledDate'] = (current) => {
   // Can not select days before today and today
   // return current && current < dayjs().subtract(1, 'day').endOf('day');
   return current && current < dayjs().endOf('day');
};

export const emailValidation = (value: string) => {
   const regex = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,63}$/;
   return !!regex.test(value);
};

export const numberValidation = (value: string) => {
   const regex = /^\d+$/;
   return !!regex.test(value);
};

export const getAmountDiscount = (amount: number, discount: number) => {
   return (amount * discount) / 100;
};

export const getAmountByMonth = (amount: number, commission: number, months: number) => {
   return (amount + amount * commission) / months;
};

export const getAmountWithCommision = (amount: number, commission: number) => {
   return amount + amount * commission;
};

export const getTotalDeferred = (option: number, amount: number) => {
   switch (option) {
      case 3:
         return getAmountWithCommision(amount, 0.053);
      case 6:
         return getAmountWithCommision(amount, 0.08);
      case 9:
         return getAmountWithCommision(amount, 0.11);
      default:
         return getAmountWithCommision(amount, 0.14);
   }
};

export const getSellPriceByLocale = (locale: 'es' | 'es-ES' | 'co' | 'en') => {
   switch (locale) {
      case 'co':
         return 'sell_price_co';
      case 'en':
         return 'sell_price_usd';
      case 'es-ES':
         return 'sell_price_eur';
      default:
         return 'sell_price';
   }
};

export const formatter = (locale: 'es' | 'es-ES' | 'co' | 'en') => {
   let currency;

   // Determinamos la moneda según la localización
   switch (locale) {
      case 'en': // Estados Unidos
      case 'co': // Colombia
         currency = 'USD';
         break;
      case 'es-ES': // España
         currency = 'EUR';
         break;
      case 'es': // México
      default:
         currency = 'MXN';
   }

   // Crear el formateador con la localización y la moneda correspondiente
   return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
   });
};

export const getPrefixEndpoints = (locale: 'es' | 'es-ES' | 'co' | 'en') => {
   if (locale === 'en') {
      return locale;
   }
   return 'es';
};
