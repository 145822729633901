import { api, returningError } from 'src/api';
import { UserRequestI, store } from 'src/redux';
import { getPrefixEndpoints } from 'src/utils';

export const postSignIn = async (body: UserRequestI) => {
   try {
      const { data }: any = await api.post(`/${getPrefixEndpoints(store.getState().app.locale)}/v1/sign_in`, body);
      return data;
   } catch (error: any) {
      return returningError(error);
   }
};
