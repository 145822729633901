import { store } from 'src/redux';
import { api, returningError } from 'src/api';
import { getPrefixEndpoints } from 'src/utils';

export const getCategories = async () => {
   try {
      const { data }: any = await api.get(`/${getPrefixEndpoints(store.getState().app.locale)}/v1/categories`, {
         params: {
            raw: true,
         },
      });
      return { ...data, success: 'true' };
   } catch (error: any) {
      return returningError(error);
   }
};
