import React from 'react';

import { Divider, Radio, RadioChangeEvent, Space } from 'antd';

interface SelectLanguageI {
   locale: string;
   lang: string;
   onChangeLocale: (value: string) => void;
   onChangeLang: (value: string) => void;
}

const SelectLanguage = ({ locale, lang, onChangeLocale, onChangeLang }: SelectLanguageI) => {
   const handleChangeLocale = (e: RadioChangeEvent) => onChangeLocale(e.target.value);

   const handleChangeLang = (e: RadioChangeEvent) => onChangeLang(e.target.value);

   return (
      <div>
         <Radio.Group onChange={handleChangeLocale} value={locale}>
            <Space direction='vertical'>
               <Radio value={'es'}>
                  <Space>
                     <span role='img' aria-label='mx'>
                        🇲🇽
                     </span>
                     {lang === 'es' ? 'México' : 'Mexico'}
                  </Space>
               </Radio>
               <Radio value={'en'}>
                  <Space>
                     <span role='img' aria-label='eu'>
                        🇺🇸
                     </span>
                     {lang === 'es' ? 'Estados Unidos' : 'United States'}
                  </Space>
               </Radio>
               <Radio value={'es-ES'}>
                  <Space>
                     <span role='img' aria-label='es-ES'>
                        🇪🇸
                     </span>
                     {lang === 'es' ? 'España' : 'Spain'}
                  </Space>
               </Radio>
               <Radio value={'co'}>
                  <Space>
                     <span role='img' aria-label='co'>
                        🇨🇴
                     </span>
                     {lang === 'es' ? 'Colombia' : 'Colombia'}
                  </Space>
               </Radio>
               {/* <Radio value={'BR'}>
                        <Space>
                        <span role='img' aria-label='br'>
                            🇧🇷
                        </span>Brasil
                        </Space>
                    </Radio> */}
            </Space>
         </Radio.Group>
         <Divider />
         <Radio.Group onChange={handleChangeLang} value={lang}>
            <Space direction='vertical'>
               <Radio value={'es'}>{lang === 'es' ? 'Español' : 'Spanish'}</Radio>
               <Radio value={'en'}>{lang === 'es' ? 'Ingles' : 'English'}</Radio>
               {/* <Radio value={'PT'}>Portugues</Radio> */}
            </Space>
         </Radio.Group>
      </div>
   );
};
export default SelectLanguage;
