import { store } from 'src/redux';
import { api, returningError } from 'src/api';
import { getPrefixEndpoints } from 'src/utils';

export const getProductsByCategory = async (id: string) => {
   try {
      const { data }: any = await api.get(`/${getPrefixEndpoints(store.getState().app.locale)}/v1/products`, {
         params: {
            raw: true,
            category: id,
         },
      });
      return { ...data, success: 'true' };
   } catch (error: any) {
      return returningError(error);
   }
};

export const getProductsByZone = async (id: string) => {
   try {
      const { data }: any = await api.get(`/${getPrefixEndpoints(store.getState().app.locale)}/v1/products`, {
         params: {
            raw: true,
            zone: id,
         },
      });
      return { ...data, success: 'true' };
   } catch (error: any) {
      return returningError(error);
   }
};

export const validatePromoCode = async (code: string) => {
   try {
      const { data }: any = await api.get(`/${getPrefixEndpoints(store.getState().app.locale)}/v1/promocodes/validate/${code}`);

      return { ...data, success: 'true' };
   } catch (error) {
      return returningError(error);
   }
};
