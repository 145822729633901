import React, { useEffect, useState } from 'react';

import { Button, Card, Col, notification, Row, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import styles from '../Services.module.css';
import { AppI, CommonI, ProductsI, getProductsByZoneId, setCurrentProduct, setCurrentService, setProdCart } from 'src/redux';

import homeI18n from 'src/i18n/home.i18n.json';
import servInfI18n from 'src/i18n/serviceinfo.i18n.json';
import notifI18n from 'src/i18n/notifications.i18n.json';
import { formatter, getSellPriceByLocale } from 'src/utils';

const Detail = () => {
   const { id } = useParams();
   const { currentService, services, products, productsIsFetching, currentZone } = useAppSelector(({ common }: { common: CommonI }) => common);
   const { lang, locale } = useAppSelector(({ app }: { app: AppI }) => app);
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const [api, contextHolder] = notification.useNotification();

   // const [service, setService] = useState('00');
   const [typeService, setTypeService] = useState<'premium' | 'estandar'>('premium');
   const [serviceSelected, setServiceSelected] = useState<undefined | ProductsI>(undefined);
   const [width, setWidth] = useState(window.innerWidth);
   // get producs
   const getProducts = () => {
      dispatch(getProductsByZoneId(String(currentZone.id)));
   };
   // resize event
   useEffect(() => {
      window.addEventListener('resize', () => {
         setWidth(window.innerWidth);
      });
      return () => window.removeEventListener('resize', () => {});
   }, []);
   // map services and set service selected for get service info
   useEffect(() => {
      if (services && services.length > 0) {
         if (currentService) {
            const serviceFiltered: Array<ProductsI> = services.filter((i: ProductsI) => Number(i.id) === Number(currentService));
            setServiceSelected(serviceFiltered.length > 0 ? serviceFiltered[0] : undefined);
            serviceFiltered.length > 0
               ? setTypeService(serviceFiltered[0].name[lang].toLowerCase().indexOf('premium') > -1 ? 'premium' : 'estandar')
               : setTypeService('estandar');
         } else {
            if (id) {
               const serviceFiltered: Array<ProductsI> = services.filter((i: ProductsI) => Number(i.id) === Number(id));
               setServiceSelected(serviceFiltered.length > 0 ? serviceFiltered[0] : undefined);
               serviceFiltered.length > 0
                  ? setTypeService(serviceFiltered[0].name[lang].toLowerCase().indexOf('premium') > -1 ? 'premium' : 'estandar')
                  : setTypeService('estandar');
            }
         }
      }
   }, [services, currentService]);
   // get products when id or service change
   useEffect(() => {
      if (currentService || id) {
         getProducts();
      }
   }, [currentService, id, locale]);
   // set current servixe
   useEffect(() => {
      if (currentService) {
         // setService(currentService);
         if (currentService !== id) dispatch(setCurrentService(currentService));
      } else {
         if (id && currentService !== id) {
            // setService(id);
            dispatch(setCurrentService(id));
         }
      }
   }, [currentService, id]);

   useEffect(() => {
      if (currentZone.locality === '') navigate('/', { replace: true });
   }, [currentZone.locality]);

   return (
      <>
         {contextHolder}
         {/* <Row style={ { backgroundColor: '#FFFFFF' }}>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 40 }} 
                    xs={24} sm={24} md={{ offset: 4, span: 16 }} lg={{ offset: 8, span: 8 }} xl={{ offset: 8, span: 8 }} xxl={{ offset: 8, span: 8 }}
                >
                    <Select
                        placeholder={homeI18n[lang].selectPlaceholderServices}
                        style={{ minWidth: 300 }}
                        options={services.length > 0 ? [{ value: '00', label: homeI18n[lang].selectChooseService }, ...services.map((i:any) => ({ value: `${i.id}`, label: i.name[lang] || 'Sin Nombre' }))] : [{ value: '00', label: homeI18n[lang].selectNoResult }]}
                        onChange={(value) => {
                            setService(value)
                            dispatch(setCurrentService(value !== '00' ? value : ''));
                            if (value !== '00') navigate(`/servicios/${value}`);
                        }}
                        value={service}
                    />
                </Col>
            </Row> */}
         <div className={styles.services}>
            <div className='container text-center pt-2 pb-5'>
               <Button type='primary' style={{ width: '40%', marginBottom: 20 }} onClick={() => navigate('/', { replace: true })}>
                  {servInfI18n[lang].btnBuying}
               </Button>
               {/* <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className={typeService === 'premium' ? styles.services_premium_type : styles.services_standar_type} style={{ width: 300, marginBottom: 10 }}>
                            {lang === 'es' ? `Servicio ${ucFirst(typeService)}` : `${ucFirst(typeService)} Service`}
                        </div>
                    </div> */}
               <div>
                  <h2>
                     {`${serviceSelected?.name[lang]} `}
                     {/* <span>{typeService}</span> */}
                  </h2>
                  {/* <p>{lang === 'es' ? `${servInfI18n[lang].brandsUsed} ${typeService}` : `${typeService} ${servInfI18n[lang].brandsUsed}`}</p> */}
               </div>
               <Spin spinning={productsIsFetching} size='large'>
                  <Row gutter={[5, 15]}>
                     {products.length > 0 ? (
                        <>
                           {products
                              .filter((i: ProductsI) => Number(i.category_id) === Number(currentService || id))
                              .map((item: ProductsI, index) => (
                                 <Col
                                    key={index}
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={products.length < 3 ? 12 : 8}
                                    xl={products.length < 3 ? 12 : 8}
                                    xxl={products.length < 3 ? 12 : 8}
                                    style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Card
                                       style={{ width: width < 768 ? (width < 360 ? 300 : 345) : products.length < 3 ? 400 : '90%' }}
                                       hoverable
                                       cover={
                                          <img
                                             style={{ objectFit: 'cover' }}
                                             alt={`img-${serviceSelected ? serviceSelected?.name[lang].toLowerCase() : 'example'}-${index + 1}`}
                                             width={'100%'}
                                             height={300}
                                             src={item.photo}
                                          />
                                       }>
                                       <h4 style={{ textAlign: 'left' }}>{item.name[lang]}</h4>
                                       <p style={{ textAlign: 'right' }}>
                                          {formatter(locale).format(item[getSellPriceByLocale(locale)]).replace('MX', '')}
                                       </p>
                                       <Button
                                          size='large'
                                          style={{ width: '100%', marginBottom: 10 }}
                                          type='primary'
                                          onClick={() => {
                                             dispatch(setCurrentProduct(item));
                                             navigate(
                                                `detalle/${item.id}?title=${serviceSelected ? serviceSelected?.name[lang].toLowerCase() : 'none'}`
                                             );
                                          }}>
                                          {servInfI18n[lang].btnDetail}
                                       </Button>
                                       <Button
                                          size='large'
                                          style={{ width: '100%', marginBottom: 10 }}
                                          type='primary'
                                          onClick={() => {
                                             // dispatch(setCurrentProduct(item));
                                             // navigate(`detalle/${item.id}?title=${serviceSelected ? serviceSelected?.name[lang].toLowerCase() : 'none'}`)
                                             api.success({
                                                message: notifI18n[lang].serviceTitleNotification,
                                                description: notifI18n[lang].serviceDescriptionNotification,
                                                placement: 'topLeft',
                                             });
                                             dispatch(
                                                setProdCart({
                                                   id: item.id,
                                                   nameProduct: item.name,
                                                   numberProducts: 1,
                                                   cost: item[getSellPriceByLocale(locale)],
                                                   uri: item.photo,
                                                })
                                             );
                                          }}
                                          ghost>
                                          {servInfI18n[lang].btnBuy}
                                       </Button>
                                    </Card>
                                 </Col>
                              ))}
                        </>
                     ) : (
                        <>
                           <p>{servInfI18n[lang].nothingShow}</p>
                        </>
                     )}
                  </Row>
               </Spin>
               <Button type='primary' style={{ width: '40%', marginTop: 20 }} onClick={() => navigate('/', { replace: true })}>
                  {servInfI18n[lang].btnBuying}
               </Button>
               <p style={{ fontFamily: 'Gotham', fontSize: 14, marginTop: 20 }}>{homeI18n[lang].common}</p>
            </div>
         </div>
      </>
   );
};
export default Detail;
